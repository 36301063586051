import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/el';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
moment().locale('el');

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? "/";
root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);

reportWebVitals();

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

function dec2hex(dec: any) {
  return dec.toString(16).padStart(2, "0")
}

export const generateId = (len: number) => {
  var arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}
