import { Avatar, message, Button, Card, Col, List, PageHeader, Switch, Typography, Badge, Modal, Row, Descriptions, Grid, Table, Statistic, Space, Dropdown, MenuProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../App';
import { Answers, HomePageStats, ServerAnswersToModel, TestType, UserInfo } from '../models/models';
import { PlusOutlined, DownloadOutlined, CalendarTwoTone, CalendarFilled, ExclamationCircleOutlined, DownOutlined } from '@ant-design/icons';
import { AnswersTable } from '../components/answersTable';

const Dashboard: React.FC = props => {
    const context = useContext(AppContext);
    const [ready, setReady] = useState(false);
    const [users, setUsers] = useState<Array<UserInfo>>([]);
    const [homePageStats, setHomePageStats] = useState<HomePageStats>({ newComments: 0, newPresences: 0, newSkills: 0, newUsers: 0 });
    const [answers, setAnswers] = useState<Array<Answers>>([]);

    const navigate = useNavigate();
    const screens = Grid.useBreakpoint();

    useEffect(() => {
        if (ready) {
            return;
        }

        (async () => {
            const response = await fetch("/api/Test/Answers", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                let ans: Array<Answers> = ServerAnswersToModel(await response.json());
                setAnswers(ans);
            } else {
                setAnswers([]);
            }

            setReady(true);
        })();

        (async () => {
            const response = await fetch("/api/user/Home", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                setHomePageStats(await response.json());
            } else {
                setHomePageStats({ newComments: 0, newPresences: 0, newSkills: 0, newUsers: 0 });
            }
        })();
    }, [ready]);

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        navigate(`/test/${e.key}`, { replace: true });
    };

    const handleMenuClick2: MenuProps['onClick'] = (e) => {
        window.open(`/api/Test/Export?testType=${e.key}`, '_blank');
    };

    const menuProps: MenuProps = {
        items: [
            {
                label: TestType[0],
                key: '0',
            },
            {
                label: TestType[1],
                key: '1',
            },
            {
                label: TestType[2],
                key: '2',
            },
            {
                label: TestType[3],
                key: '3',
            },
            {
                label: TestType[4],
                key: '4',
            }
        ],
        onClick: handleMenuClick,
    };

    const menuProps2: MenuProps = {
        items: [
            {
                label: TestType[0],
                key: '0',
            },
            {
                label: TestType[1],
                key: '1',
            },
            {
                label: TestType[2],
                key: '2',
            },
            {
                label: TestType[3],
                key: '3',
            },
            {
                label: TestType[4],
                key: '4',
            }
        ],
        onClick: handleMenuClick2,
    };

    return (<>
        <PageHeader
            className="site-page-header-responsive"
        >
        </PageHeader>
        <Content>
            <Card title="Αξιολογήσεις" bordered hoverable className="always" style={{ marginTop: "24px" }}
                extra={
                    <Space.Compact>
                        <Dropdown menu={menuProps}>
                            <Button type="primary">
                                <Space>
                                    Δημιουργία νέας <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                        <Dropdown menu={menuProps2}>
                            <Button type="default">
                                <Space>
                                    Εξαγωγή <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Space.Compact>
                }>
                <AnswersTable answers={answers} setReady={setReady}></AnswersTable>
            </Card>

            <Card bordered hoverable className="always" style={{ marginTop: "50px" }}>
                &copy; 2023 - Clinical Mozart - Powered by <a href="https://biostats.gr/e-crf-edc/">biostats.gr</a>
            </Card>
        </Content>
    </>);
}

export default Dashboard;
