import { Button, Card, Col, Descriptions, Divider, Form, Input, PageHeader, Row, Space, Tabs, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Radar } from '@ant-design/plots';
import { useReactToPrint } from 'react-to-print';
import moment from "moment";
import { red, green, yellow } from '@ant-design/colors';
import { DiagnosisResult, EvaluationResultDb, InternalExtrernalLabels } from "../models/models";
import { LEPSCharts, LEPSDSMCharts, LEPSExtraCharts, LEPSResultRender } from "../components/LepsResultRender";
import { report } from "process";

export const Results: React.FC = props => {
    const [ready, setReady] = useState(false);
    const [results, setResults] = useState<Array<{}>>([]);
    let { id } = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [evaluation, setEvaluation] = useState<EvaluationResultDb | null>(null);
    const [reportText, setReportText] = useState<Array<string>>(["", "", ""]);
    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Αναφορά",
    });

    const getDefaultText = () => {
        return `Στις ${moment(evaluation?.date).format("DD/MM/YYYY")} παρουσιάστηκε στο προσωπικό μου γραφείο και κατά τη διάρκεια της συνεδρίας συμπλήρωσε την κλίμακα ΣΑΕΒΑ (Σύστημα
        Achenbach για Εμπειρικά Βασισμένη Αξιολόγηση ) λόγω του/της ${evaluation?.name} Κατά την αξιολόγηση των αποτελεσμάτων στο προφίλ ΛΕΠ προέκυψαν τιμές που
        ανήκουν κυρίως στο φυσιολογικό φάσμα.
        Οι διαστάσεις στις οποίες εξετάστηκε και η αντίστοιχη επίδοση παρουσιάζονται ακολούθως:
        `
    };

    useEffect(() => {
        if (ready) {
            return;
        }

        (async () => {
            const response = await fetch(`/api/Test/GetEvaluation?id=${id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                const info: EvaluationResultDb = await response.json();

                if (info.evaluationJson) {
                    info.evaluation = JSON.parse(info.evaluationJson);
                }

                setEvaluation(info);
            } else {
                setEvaluation(null);
            }


            setReady(true);
        })();
    }, [id]);

    useEffect(() => {
        if (!evaluation) {
            return;
        }

        form.setFieldValue("paragraph", getDefaultText());
        let rt = reportText;
        setReportText([getDefaultText(), rt[1], rt[2]]);
    }, [evaluation]);

    const onFinish = (values: any) => {
        setReportText([form.getFieldValue("paragraph"), form.getFieldValue("paragraph2"), form.getFieldValue("paragraph3")]);
    }

    return (<><PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title={""}
    >
    </PageHeader>
        <Content>
            <Card>
                <Tabs
                    tabPosition="top"
                    items={[
                        {
                            label: <span style={{ fontSize: "16px" }}>Αποτελέσματα αξιολόγησης</span>,
                            key: "0",
                            children: <>
                                {evaluation && evaluation.testType >= 0 && <LEPSResultRender evaluation={evaluation}></LEPSResultRender>}
                            </>
                        },
                        {
                            label: <span style={{ fontSize: "16px" }}>Αναφορά</span>,
                            key: "1",
                            children: <>
                                <Card bordered hoverable className="always"
                                    actions={[<Button type="primary" onClick={() => { form.submit(); }}>Ανανέωση</Button>]}>
                                    <Form form={form} onFinish={onFinish} layout="vertical">
                                        <Form.Item name={"paragraph"} label={"Αρχικό κείμενο αναφοράς"}>
                                            <Input.TextArea rows={6} />
                                        </Form.Item>

                                        <Form.Item name={"paragraph2"} label={"Κείμενο πριν το DSM"}>
                                            <Input.TextArea rows={6} />
                                        </Form.Item>

                                        <Form.Item name={"paragraph3"} label={"Επίλογος"}>
                                            <Input.TextArea rows={6} />
                                        </Form.Item>
                                    </Form>
                                </Card>

                                <Card title={`Αναφορά`} bordered hoverable className="always"
                                    extra={<Button type="primary" onClick={() => { handlePrint(); }}>Εξαγωγή PDF</Button>}>
                                    <>
                                        <Row justify="start" gutter={[0, 0]} wrap style={{ width: "210mm", margin: "auto", marginBottom: "24px", height: "initial !important", overflow: "initial !important" }} ref={componentRef}>
                                            <style type="text/css" media="print">{"\
                        @page {\ size: A4;\ margin: 1.5cm; }\
                        @media print {\
                            .page-break {\
                            margin-top: 1rem;\
                            display: block;\
                            page-break-before: auto;\
                            }\
                            .dashboard-layout-content { background: white !important; }\
                            .page-break-always {\
                                page-break-before: always;\
                            }\
                            .ant-table-wrapper {\
                                page-break-inside: avoid;\
                            }\
                            .ant-descriptions-item {\
                                padding-bottom:0px !important;\
                            }\
                            .no-print, .no-print * {\
                                display: none !important;\
                            } \
                            html, body {\
                                height: initial !important;\
                                margin: 0 !important;\
                                padding: 0 !important;\
                                overflow: initial !important;\
                                -webkit-print-color-adjust: exact;\
                                background: white;\
                              }\
                        }\
                    "}</style>
                                            <Row className="ant-table-wrapper" style={{ width: "100%" }}>
                                                <Space direction="vertical" style={{ width: "100%", textAlign: "center" }}>
                                                    <Typography.Title level={4}>Δρ. Καλλίνικου Α. Ελευθερία</Typography.Title>
                                                    <Typography.Title level={5}>Ψυχολόγος</Typography.Title>
                                                    <Typography.Title level={5}>MSc Κοινωνικής Ψυχιατρικής Παιδοψυχιατρικής Ιατρική σχολή Πανεπιστημίου Ιωαννίνων</Typography.Title>
                                                    <Typography.Title level={5}>Διδάκτωρ Ιατρικής σχολής Πανεπιστημίου Ιωαννίνων</Typography.Title>
                                                    <Typography.Title level={5}>Βασ. Πύρρου 46 Άρτα 2681026555 - 6976615339</Typography.Title>
                                                    <Divider dashed ></Divider>
                                                    <Typography.Title level={5} style={{ textAlign: "right" }}>Άρτα {moment().format("DD/MM/YYYY")}</Typography.Title>
                                                    <Typography.Title level={4}>ΓΝΩΜΑΤΕΥΣΗ</Typography.Title>
                                                </Space>
                                                <Col span={24}>
                                                    {reportText[0]}
                                                </Col>
                                                <Col span={24}>
                                                    Συγκεκριμένα:
                                                    <Descriptions bordered layout="horizontal" size={"small"} column={1}>
                                                        {evaluation && evaluation.evaluation && evaluation.evaluation.CategoriesNames && evaluation.evaluation.CategoriesNames.map((cn, i) => {
                                                            if (i >= evaluation.evaluation.CategoriesDiagnosis.length) {
                                                                return <></>;
                                                            }

                                                            return <Descriptions.Item key={i} label={cn} contentStyle={evaluation.evaluation.CategoriesDiagnosis[i] > 0 ? { fontWeight: "bold" } : {}}>{DiagnosisResult[evaluation.evaluation.CategoriesDiagnosis[i]]}</Descriptions.Item>
                                                        })}

                                                        <Descriptions.Item labelStyle={{ fontStyle: "italic" }} contentStyle={(evaluation?.evaluation.InnerConditionDiagnosis ?? 0) > 0 ? { fontWeight: "bold" } : {}} label={InternalExtrernalLabels[0]}>{DiagnosisResult[evaluation?.evaluation.InnerConditionDiagnosis ?? 0]}</Descriptions.Item>
                                                        <Descriptions.Item labelStyle={{ fontStyle: "italic" }} contentStyle={(evaluation?.evaluation.ExternalConditionDiagnosis ?? 0) > 0 ? { fontWeight: "bold" } : {}} label={InternalExtrernalLabels[1]}>{DiagnosisResult[evaluation?.evaluation.ExternalConditionDiagnosis ?? 0]}</Descriptions.Item>
                                                        <Descriptions.Item labelStyle={{ fontWeight: "bold" }} contentStyle={(evaluation?.evaluation.TotalConditionDiagnosis ?? 0) > 0 ? { fontWeight: "bold" } : {}} label="ΣΥΝΟΛΙΚΑ ΠΡΟΒΛΗΜΑΤΑ">{DiagnosisResult[evaluation?.evaluation.TotalConditionDiagnosis ?? 0]}</Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                {evaluation?.evaluation.ExtraCategories && <>
                                                    <Typography.Text>Επίσης στις κλίμακες ικανοτήτων: </Typography.Text>
                                                    <Col span={24}>
                                                        <Descriptions bordered layout="horizontal" size={"small"} column={1}>
                                                            {evaluation && evaluation.evaluation && evaluation.evaluation.ExtraCategories && evaluation.evaluation.ExtraCategories.map((cn, i) => {
                                                                return <Descriptions.Item key={i} label={cn} contentStyle={evaluation.evaluation.ExtraCategoriesDiagnosis[i] > 0 ? { fontWeight: "bold" } : {}}>{DiagnosisResult[evaluation.evaluation.ExtraCategoriesDiagnosis[i]]}</Descriptions.Item>
                                                            })}

                                                        </Descriptions>
                                                    </Col>
                                                </>
                                                }
                                                <Col span={24}>
                                                    {reportText[1]}
                                                </Col>
                                                {evaluation?.evaluation?.DSMResults &&
                                                    <Col span={24}>
                                                        Συγκεκριμένα:
                                                        <Descriptions bordered layout="horizontal" size={"small"} column={1}>
                                                            {evaluation && evaluation.evaluation && evaluation.evaluation.DSMResults && evaluation.evaluation.DSMResults.CategoriesNames && evaluation.evaluation.DSMResults.CategoriesNames.map((cn, i) => {
                                                                return <Descriptions.Item key={i} label={cn} contentStyle={evaluation.evaluation.DSMResults.CategoriesDiagnosis[i] > 0 ? { fontWeight: "bold" } : {}}>{DiagnosisResult[evaluation.evaluation.DSMResults.CategoriesDiagnosis[i]]}</Descriptions.Item>
                                                            })}
                                                        </Descriptions>
                                                    </Col>
                                                }
                                                <Col span={24} className="ant-table-wrapper">
                                                    {reportText[2]}
                                                </Col>
                                            </Row>
                                            <Row style={{ width: "100%" }}>
                                                <Divider className="no-print"></Divider>
                                                <div className="page-break page-break-always"></div>
                                                {evaluation &&
                                                    <Col span={24}>
                                                        <Card title={`Κλίμακες συνδρόμων`} bodyStyle={{ display: 'none' }} bordered hoverable className="always" style={{ width: "100%" }}>
                                                        </Card>
                                                        <Row style={{ width: "100%" }}>
                                                            <LEPSCharts evaluation={evaluation}></LEPSCharts>
                                                        </Row>
                                                    </Col>
                                                }
                                                {evaluation && evaluation.evaluation && evaluation.evaluation.ExtraCategories &&
                                                    <Col span={24}>
                                                        <Card title={`Κλίμακες ικανοτήτων`} bodyStyle={{ display: 'none' }} bordered hoverable className="always" style={{ width: "100%" }}>
                                                        </Card>
                                                        <Row style={{ width: "100%" }}>
                                                            <LEPSExtraCharts evaluation={evaluation}></LEPSExtraCharts>
                                                        </Row>
                                                    </Col>
                                                }

                                                {evaluation?.evaluation?.DSMResults && <>
                                                    <div className="page-break page-break-always"></div>
                                                    <Col span={24}>
                                                        <Card title={`Κλίμακες DSM`} bodyStyle={{ display: 'none' }} bordered hoverable className="always" style={{ marginLeft: "14px", width: "100%" }}>
                                                        </Card>
                                                        <Row style={{ width: "100%" }}>
                                                            {evaluation && evaluation?.evaluation?.DSMResults && <LEPSDSMCharts evaluation={evaluation}></LEPSDSMCharts>}
                                                        </Row>
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </Row>
                                    </>
                                </Card>
                            </>
                        }
                    ]}>
                </Tabs>
            </Card>
        </Content>
    </>);
}