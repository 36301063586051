import { ReloadOutlined } from "@ant-design/icons";
import { Select, Input, DatePicker, Form, Checkbox, Col, Row, Space, Card, Button, message, Divider, Radio } from "antd";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateId, groupBy } from "..";
import { Answers, EvaluationResultDb, Question, TestType } from "../models/models";

export const TestComponent: React.FC<{ testType: string | undefined, questions: Array<Question>, answers?: Answers }> = ({ testType, questions, answers, ...props }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const dateFields = ["q_3006", "q_6", "q_1005", "q_2005", "q_4004"];


    const initializeForm = () => {
        let iv: { [id: string]: string } = {};

        questions.forEach((v, i) => {
            if (v.isText) {
                return;
            }

            if (v && v.options && v.options.length > 0 && v.options.filter(o => o.value == "-1").length > 0) {
                iv[`q_${v.id}`] = "-1";
            }
            else {
                iv[`q_${v.id}`] = "0";
            }
        });

        form.setFieldsValue(iv);
    }

    useEffect(() => {
        if (answers) {
            return;
        }

        initializeForm();
    }, [questions, form]);

    useEffect(() => {
        if (!answers) {
            initializeForm();
            return;
        }

        for (let i = 0; i < dateFields.length; i++) {
            let f = dateFields[i];
            if (answers.answersParsed.hasOwnProperty(f)) {
                answers.answersParsed[f] = moment(answers.answersParsed[f]);
            }
        }

        form.setFieldsValue(answers.answersParsed);
    }, [answers]);

    const onFinish = async (values: any) => {
        const response = await fetch(`/api/Test/Submit`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                testType: testType,
                modelJson: JSON.stringify(values)
            }),
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την επεξεργασία των αποτελεσματων");
            return;
        }

        var answersId = await response.json();

        navigate(`/`, { replace: true });
    }

    const clickButton = () => {
        form
            .validateFields()
            .then(values => {
                onFinish(values);
            })
            .catch(info => {
                console.log('Τα δεδομένα δεν είναι έγκυρα', info);
            });
    }

    return (
        <Card title={`Ερωτηματολόγιο ${TestType[testType ?? "0"]}`} bordered hoverable className="always"
            extra={<Button type="primary" disabled={answers != undefined && answers.id != null} onClick={clickButton}>Υποβολή</Button>}
            actions={[
                <Button type="primary" disabled={answers != undefined && answers.id != null} onClick={clickButton} style={{ marginLeft: "auto" }}>Υποβολή</Button>,
            ]}>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 16]} wrap justify={"space-between"}>
                    {testType == "0" && <TRFTest questions={questions}></TRFTest>}
                    {testType == "1" && <CBCLTest questions={questions}></CBCLTest>}
                    {testType == "2" && <YSRTest questions={questions}></YSRTest>}
                    {testType == "3" && <LEPSTest questions={questions}></LEPSTest>}
                    {testType == "4" && <LEPSTest questions={questions}></LEPSTest>}
                </Row>
            </Form>
        </Card>
    );
}

export const QuestionRender: React.FC<{ v: Question, index: number }> = ({ v, index, ...props }) => {
    const form = Form.useFormInstance();

    const generateRandomName = (key: string) => {
        let name = generateId(20);
        form.setFieldValue(key, name);
    }

    return (<>
        {v.isText && v.label != "Ημ/νία Γέννησης" && index != 0 &&
            <Form.Item key={`${v.id}_c1`} name={`q_${v.id}`} label={v.label} rules={[{ required: index == 0, message: "Το πεδίο είναι υποχρεωτικό" }]}>
                <Input />
            </Form.Item>
        }
        {v.isText && v.label != "Ημ/νία Γέννησης" && index == 0 &&
            <Form.Item key={`${v.id}_c1`} name={`q_${v.id}`} label={v.label} rules={[{ required: index == 0, message: "Το πεδίο είναι υποχρεωτικό" }]}>
                <Input.Search enterButton={<ReloadOutlined />} onSearch={() => { generateRandomName(`q_${v.id}`); }} />
            </Form.Item>
        }
        {
            v.isText && v.label == "Ημ/νία Γέννησης" &&
            <Form.Item key={`${v.id}_c1`} name={`q_${v.id}`} label={v.label} rules={[{ required: true, message: "Το πεδίο είναι υποχρεωτικό" }]}>
                <DatePicker style={{ width: "100%" }} />
            </Form.Item>
        }
        {
            !v.isText && <Input.Group>
                <Form.Item key={`${v.id}_c2`} name={`q_${v.id}`} label={v.label}>
                    {v.options && <Radio.Group options={v.options ?? []}>
                    </Radio.Group>}
                </Form.Item>
                {v.hasExtraText &&
                    <Form.Item style={{}} key={`${v.id}_c3`} name={`q_${v.id}_extra`}>
                        <Input />
                    </Form.Item>
                }
            </Input.Group>
        }
    </>)
}

export const TRFTest: React.FC<{ questions: Array<Question> }> = ({ questions, ...props }) => {
    const [groups, setGroups] = useState<Record<number, Question[]>>([]);

    useEffect(() => {
        if (!questions || questions.length == 0) {
            return;
        }

        var groups = groupBy(questions, q => q.order);

        for (let i = 3; i < 6; i++) {
            if (!groups.hasOwnProperty(i)) {
                continue;
            }

            let l = groups[i].length / 2;
            groups[i].sort((a, b) => a.id - b.id);
            let a = groups[i].map((v, i) => { return { value: v, index: i }; });

            a.sort((a, b) => a.index % l - b.index % l);
            groups[i] = a.map((v, i) => v.value);
        }
        setGroups(groups);
    }, [questions]);

    return (<>
        {questions.filter(v => v.order < 3).map((v, i) => {
            return (<>
                {i > 0 && questions[i - 1].order != v.order && <Col span={24}><Divider></Divider></Col>}
                <Col span={v.order > 2 ? 12 : 6} key={`${v.id}_col`}>
                    <QuestionRender v={v} index={i}></QuestionRender>
                </Col>
            </>
            )
        })}
        {[3, 4, 5].map((index) => {
            return (
                <>{groups.hasOwnProperty(index) && <>
                    <Col span={24}><Divider></Divider></Col>
                    {groups[index].map((v, i, arr) => {
                        return (<Col span={v.order > 2 ? 12 : 6} key={`${v.id}_col`}>
                            <QuestionRender v={v} index={i}></QuestionRender>
                        </Col>
                        )
                    })}
                </>}
                </>
            )
        })}
    </>);
}


export const YSRTest: React.FC<{ questions: Array<Question> }> = ({ questions, ...props }) => {
    const [groups, setGroups] = useState<Record<number, Question[]>>([]);

    useEffect(() => {
        if (!questions || questions.length == 0) {
            return;
        }

        var groups = groupBy(questions, q => q.order);

        for (let i = 3; i < 6; i++) {
            if (!groups.hasOwnProperty(i)) {
                continue;
            }

            let l = groups[i].length / 2;
            let a = groups[i].map((v, i) => { return { value: v, index: i }; });
            a.sort((a, b) => a.index % l - b.index % l);
            groups[i] = a.map((v, i) => v.value);
        }
        setGroups(groups);
    }, [questions]);

    return (<>
        {questions.filter(v => v.order < 3).map((v, i) => {
            return (<>
                {i > 0 && questions[i - 1].order != v.order && <Col key={`${v.id}_col_sep`} span={24}><Divider></Divider></Col>}
                <Col span={v.order > 2 ? 12 : 6} key={`${v.id}_col`}>
                    <QuestionRender v={v} index={i}></QuestionRender>
                </Col>
            </>
            )
        })}
        {[3, 4, 5].map((index) => {
            return (
                <>{groups.hasOwnProperty(index) && <>
                    <Col key={`group_${index}_col_sep`} span={24}><Divider></Divider></Col>
                    {groups[index].map((v, i, arr) => {
                        return (<Col span={v.order > 2 ? 12 : 6} key={`${v.id}_col`}>
                            <QuestionRender v={v} index={i}></QuestionRender>
                        </Col>
                        )
                    })}
                </>}
                </>
            )
        })}
    </>);
}

export const LEPSTest: React.FC<{ questions: Array<Question> }> = ({ questions, ...props }) => {
    const [groups, setGroups] = useState<Record<number, Question[]>>([]);

    useEffect(() => {
        if (!questions || questions.length == 0) {
            return;
        }

        var groups = groupBy(questions, q => q.order);

        for (let i = 3; i < 6; i++) {
            if (!groups.hasOwnProperty(i)) {
                continue;
            }

            let l = groups[i].length / 2;
            let a = groups[i].map((v, i) => { return { value: v, index: i }; });
            a.sort((a, b) => a.index % l - b.index % l);
            groups[i] = a.map((v, i) => v.value);
        }
        setGroups(groups);
    }, [questions]);

    return (<>
        {questions.filter(v => v.order < 3).map((v, i) => {
            return (<React.Fragment key={i}>
                {i > 0 && questions[i - 1].order != v.order && <Col key={`${v.id}_col_sep`} span={24}><Divider></Divider></Col>}
                <Col span={v.order > 2 ? 12 : 6} key={`${v.id}_col`}>
                    <QuestionRender v={v} index={i}></QuestionRender>
                </Col>
            </React.Fragment>
            )
        })}
        {[3, 4, 5].map((index) => {
            return (
                <React.Fragment key={index}>{groups.hasOwnProperty(index) && <>
                    <Col key={`group_${index}_col_sep`} span={24}><Divider></Divider></Col>
                    {groups[index].map((v, i, arr) => {
                        return (<Col span={v.order > 2 ? 12 : 6} key={`${v.id}_col`}>
                            <QuestionRender v={v} index={i}></QuestionRender>
                        </Col>
                        )
                    })}
                </>}
                </React.Fragment>
            )
        })}
    </>);
}


export const CBCLTest: React.FC<{ questions: Array<Question> }> = ({ questions, ...props }) => {
    const [groups, setGroups] = useState<Record<number, Question[]>>([]);

    useEffect(() => {
        if (!questions || questions.length == 0) {
            return;
        }

        var groups = groupBy(questions, q => q.order);

        for (let i = 3; i < 6; i++) {
            if (!groups.hasOwnProperty(i)) {
                continue;
            }

            let l = groups[i].length / 2;
            let a = groups[i].map((v, i) => { return { value: v, index: i }; });
            a.sort((a, b) => a.index % l - b.index % l);
            groups[i] = a.map((v, i) => v.value);
        }
        setGroups(groups);
    }, [questions]);

    return (<>
        {questions.filter(v => v.order < 3).map((v, i) => {
            return (<>
                {i > 0 && questions[i - 1].order != v.order && <Col key={`${v.id}_col_sep`} span={24}><Divider></Divider></Col>}
                <Col span={v.order > 2 ? 12 : 6} key={`${v.id}_col`}>
                    <QuestionRender v={v} index={i}></QuestionRender>
                </Col>
            </>
            )
        })}
        {[3, 4, 5].map((index) => {
            return (
                <>{groups.hasOwnProperty(index) && <>
                    <Col key={`group_${index}_col_sep`} span={24}><Divider></Divider></Col>
                    {groups[index].map((v, i, arr) => {
                        return (<Col span={v.order > 2 ? 12 : 6} key={`${v.id}_col`}>
                            <QuestionRender v={v} index={i}></QuestionRender>
                        </Col>
                        )
                    })}
                </>}
                </>
            )
        })}
    </>);
}