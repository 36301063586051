export interface UserInfo {
    id: string,
    email: string,
    admin: boolean,
    firstName: string,
    lastName: string,
}

export interface StudentInfo {
    id: string,
    name: string,
    email: string,
    motherName: string,
    fatherName: string,
    identityAuthority: string,
    identityNumber: string,
    birthDate: string,
    city: string,
    mobilePhone: string,
    placeBirth: string,
    postalCode: string,
    street: string,
    studentIdentityNumber: string,
    studentNumber: string,
    telephone: string,
}

export interface HomePageStats {
    newComments: number,
    newPresences: number,
    newUsers: number,
    newSkills: number,
}

export interface Question {
    id: number,
    label: string,
    isText: boolean,
    hasExtraText: boolean,
    order: number,
    options: Array<{ label: string, value: string }>
}

export interface Answers {
    id: number,
    testType: number,
    answersJson: string,
    name: string,
    answersParsed: any,
    dateSubmitted: Date,
    evaluationId: number,
    submittedBy: string,
    submittedByName: string,
}

export const TestType: { [id: string]: string } = {
    "0": "(TRF) Εκπαιδευτικών (παιδιά ηλικιών 6-18 ετών)",
    "1": "(CBCL) Γονέων (6-18 ετών)",
    "2": "YSR εφήβων (11 - 18 ετών)",
    "3": "(ΛΕΠΣ) Προσχολικής αγωγής για γονείς (1.5 - 6 ετών)",
    "4": "(ΦΑΝ-Β) Προσχολικής αγωγής για εκπαιδευτικούς (1.5 - 6 ετών)"
}

export const ServerAnswersToModel = (ans: Array<Answers>) => {
    for (let i = 0; i < ans.length; i++) {
        ans[i].answersParsed = JSON.parse(ans[i].answersJson);
        if (ans[i].testType == 0) {
            ans[i].name = ans[i].answersParsed["q_-1"];
        }
        else if (ans[i].testType == 1) {
            ans[i].name = ans[i].answersParsed["q_1000"];
        }
        else if (ans[i].testType == 2) {
            ans[i].name = ans[i].answersParsed["q_2000"];
        }
        else if (ans[i].testType == 3) {
            ans[i].name = ans[i].answersParsed["q_3000"];
        }
        else if (ans[i].testType == 4) {
            ans[i].name = ans[i].answersParsed["q_4000"];
        }
    }

    return ans;
}

export interface EvaluationResultDb {
    id: number,
    testType: number,
    name: string,
    age: number,
    date: number,
    gender: number,
    evaluationJson: string,
    evaluation: Evaluation,
}

export interface Evaluation {
    Id: number,
    TestType: number,
    Name: string,
    Age: number,
    Date: number,
    Gender: number,
    AnswersId: number,
    Categories: Array<Array<string>>,
    CategoriesScales: Array<Array<number>>,
    CategoriesNames: Array<string>,
    CategoriesScores: Array<number>,
    CategoriesDiagnosis: Array<number>,
    InternalCondition: number,
    ExternalCondition: number,
    OtherCondition: number,
    TotalCondition: number,
    InterConditionTscore: number,
    InnerConditionDiagnosis: number,
    ExternalConditionTScore: number,
    ExternalConditionDiagnosis: number,
    TotalConditionTscore: number,
    TotalConditionDiagnosis: number,
    ExtraCategories: Array<string>,
    ExtraCategoriesScores: Array<number>,
    ExtraCategoriesScales: Array<Array<number>>,
    ExtraCategoriesDiagnosis:Array<number>,
    DSMResults: Evaluation
}

export const InternalExtrernalLabels = ["Εσωτερικευμένα προβλήματα", "Εξωτερικευμένα προβλήματα"]
export const GenderOptions = ["Αγόρι", "Κορίτσι"]

export const DiagnosisResult: { [id: number]: string } = {
    0: "Φυσιολογικό φάσμα",
    1: "Οριακό φάσμα",
    2: "Κλινικό φάσμα"
}