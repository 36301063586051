import { Column, RadarConfig, DualAxes, DualAxesConfig, Radar, ColumnConfig } from "@ant-design/plots";
import { Card, Col, Descriptions, List, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { DiagnosisResult, EvaluationResultDb, GenderOptions, InternalExtrernalLabels, TestType } from "../models/models";
import { red, grey, blue, yellow, green } from '@ant-design/colors';
import moment from "moment";

const defaultRadarConfig = {
    xField: 'name',
    yField: 'value',
    appendPadding: [20, 10, 20, 10],
    autoFit: true,
    meta: {
        value: {
            alias: 'Διάγνωση',
            min: -1,
            max: 2,
            nice: true,
            tick: 2,
            formatter: (v: any) => DiagnosisResult[v],
        },
    },
    xAxis: {
        tickLine: null,
    },
    yAxis: {
        label: false,
        grid: {
            alternateColor: 'rgba(0, 0, 0, 0.04)',
            // line: {
            //     style: {
            //       stroke: 'black',
            //       lineWidth: 2,
            //       lineDash: [4, 5],
            //       strokeOpacity: 0.7,
            //       shadowColor: 'black',
            //       shadowBlur: 10,
            //       shadowOffsetX: 5,
            //       shadowOffsetY: 5,
            //       cursor: 'pointer'
            //     }
            //   }
        },
    },
    point: {
        size: 2,
    },
    area: {},
};


const getDefaultColumnConfig = (reversed?: boolean) => {
    return {
        data: [],
        autoFit: true,
        legend: false,
        xField: 'name',
        yField: ['value', 'value'],
        xAxis: {
            nice: true,
            label: {
                autoRotate: true,
                autoHide: false,
                autoEllipsis: false,
            },
        },
        yAxis: {
            'value': {
                alias: "Τιμή",
                min: 0,
                max: 50,
            },
        },
        geometryOptions: [
            {
                geometry: 'column',
                seriesField: 'category',
                isStack: true,
                isPercent: false,
                color: reversed === true ? [blue[6], grey[3], red[3]] : [red[3], grey[3], blue[6]],
                label: {
                    position: 'middle',
                    content: (item: any) => {
                        return item.label;
                    },
                    style: {
                        fill: '#fff',
                    },
                    autoRotate: true,
                },
            },
            {
                geometry: 'line',
                lineStyle: {
                    lineWidth: 4,
                },
                smooth: false,
                point: {
                    size: 5,
                    style: {
                        stroke: '#5B8FF9',
                        lineWidth: 2,
                    },
                },
            }
        ],
    } as DualAxesConfig;
}


export const LEPSResultRender: React.FC<{ evaluation: EvaluationResultDb }> = ({ evaluation, ...props }) => {
    const ev = evaluation.evaluation;

    return (<>
        <Card title={`Γενικά στοιχεία`} bordered hoverable className="always">
            <Descriptions layout="horizontal" size={"small"} column={3}>
                <Descriptions.Item label="Τύπος αξιολόγησης" span={3}><strong>{TestType[ev.TestType]}</strong></Descriptions.Item>
                <Descriptions.Item label="Όνομα" span={3}><strong>{ev.Name}</strong></Descriptions.Item>
                <Descriptions.Item label="Ηλικία" span={1}><strong>{ev.Age}</strong></Descriptions.Item>
                <Descriptions.Item label="Φύλο" span={1}><strong>{GenderOptions[ev.Gender]}</strong></Descriptions.Item>
                <Descriptions.Item label="Ημ/νια υποβολής" span={1}><strong>{moment(ev.Date).format('LLL')}</strong></Descriptions.Item>
            </Descriptions>
        </Card>
        <Card title={`Κλίμακες συνδρόμων`} bordered hoverable className="always">
            <Row>
                <Col>
                    <List
                        header={<Typography.Title level={5}>Προφίλ</Typography.Title>}
                        bordered
                        dataSource={ev.CategoriesNames}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <Typography.Text>{item + ":"}</Typography.Text> {ev.CategoriesScores[index]}
                            </List.Item>
                        )}
                    />
                </Col>
                {ev.ExtraCategories &&
                    <Col>
                        <List
                            header={<Typography.Title level={5}>Κλίμακες ικανοτήτων</Typography.Title>}
                            bordered
                            dataSource={ev.ExtraCategories}
                            renderItem={(item, index) => (
                                <List.Item key={index}>
                                    <Typography.Text>{item + ":"}</Typography.Text> {ev.ExtraCategoriesScores[index]?.toFixed(2)}
                                </List.Item>
                            )}
                        />
                    </Col>
                }
                <Col>
                    <List
                        header={<Typography.Title level={5}>Υπολογισμοί</Typography.Title>}
                        bordered>
                        <List.Item key={0}>
                            <Typography.Text>{InternalExtrernalLabels[0] + ":"}</Typography.Text> {ev.InternalCondition}
                        </List.Item>
                        <List.Item key={1}>
                            <Typography.Text>{InternalExtrernalLabels[1] + ":"}</Typography.Text> {ev.ExternalCondition}
                        </List.Item>
                        <List.Item key={2}>
                            <Typography.Text>{"Συνολικά προβλήματα:"}</Typography.Text> {ev.TotalCondition}
                        </List.Item>
                    </List>
                </Col>
            </Row>

            <LEPSCharts evaluation={evaluation} />
            <LEPSExtraCharts evaluation={evaluation} />
        </Card>
        {ev.DSMResults &&
            <Card title={`Κλίμακες DSM`} bordered hoverable className="always">
                <Row>
                    <Col>
                        <List
                            header={<Typography.Title level={5}>Προφίλ</Typography.Title>}
                            bordered
                            dataSource={ev.DSMResults.CategoriesNames}
                            renderItem={(item, index) => (
                                <List.Item key={index}>
                                    <Typography.Text>{item + ":"}</Typography.Text> {ev.DSMResults.CategoriesScores[index]}
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col>
                        <List
                            header={<Typography.Title level={5}>Υπολογισμοί</Typography.Title>}
                            bordered>
                            <List.Item key={2}>
                                <Typography.Text>{"Συνολικά προβλήματα:"}</Typography.Text> {ev.DSMResults.TotalCondition}
                            </List.Item>
                        </List>
                    </Col>
                </Row>
                <LEPSDSMCharts evaluation={evaluation} />
            </Card>
        }
    </>);
}

export const LEPSCharts: React.FC<{ evaluation: EvaluationResultDb, width?: string | number }> = ({ evaluation, width, ...props }) => {
    const [columnConfig, setColumnConfig] = useState<DualAxesConfig | null>(null);
    const [radarConfig, setRadarConfig] = useState<RadarConfig | null>(null);

    useEffect(() => {
        if (!evaluation) {
            return;
        }

        const ev = evaluation.evaluation;

        setRadarConfig({
            ...defaultRadarConfig,
            data: ev?.CategoriesDiagnosis?.map((d, i) => ({ name: ev.CategoriesNames[i], value: ev.CategoriesDiagnosis[i] })) ?? [],
        });

        const columnData = ev.CategoriesScales.map((cs, i) => {
            return [{ name: ev.CategoriesNames[i], category: 'Μέγιστο', label: cs[2], value: cs[2] - cs[1] },
            { name: ev.CategoriesNames[i], category: 'Οριακό', label: cs[1] - 1, value: cs[1] - cs[0] },
            { name: ev.CategoriesNames[i], category: 'Φυσιολογικό', label: cs[0], value: cs[0] },
            ]
        }).flat();

        const lineData = ev.CategoriesScores.map((cs, i) => {
            return { name: ev.CategoriesNames[i], value: ev.CategoriesScores[i] }
        });

        setColumnConfig({
            ...getDefaultColumnConfig(),
            data: [columnData, lineData],
        });

    }, [evaluation]);

    return (<>
        <Col span={24}>
            {radarConfig && <Radar style={{ width: width ?? "100%" }} key={"radar"} {...radarConfig} />}
        </Col>
        <Col span={24}>
            {columnConfig && <DualAxes key="dual axis" style={{ width: width ?? "100%" }} {...columnConfig} />}
        </Col>
    </>);
}


export const LEPSExtraCharts: React.FC<{ evaluation: EvaluationResultDb, width?: string | number }> = ({ evaluation, width, ...props }) => {
    const [extraColumnConfig, setExtraColumnConfig] = useState<DualAxesConfig | null>(null);
    const [extraRadarConfig, setExtraRadarConfig] = useState<RadarConfig | null>(null);

    useEffect(() => {
        if (!evaluation) {
            return;
        }

        const ev = evaluation.evaluation;

        if (!ev?.ExtraCategories) {
            return;
        }

        setExtraRadarConfig({
            ...defaultRadarConfig,
            data: ev?.ExtraCategoriesDiagnosis?.map((d, i) => ({ name: ev.ExtraCategories[i], value: ev.ExtraCategoriesDiagnosis[i] })) ?? [],
        });

        const extracolumnData = ev.ExtraCategoriesScales.map((cs, i) => {
            return [{ name: ev.ExtraCategories[i], category: 'Μέγιστο', label: cs[2], value: cs[2] - cs[1] },
            { name: ev.ExtraCategories[i], category: 'Οριακό', label: "<" + cs[1], value: cs[1] - cs[0] },
            { name: ev.ExtraCategories[i], category: 'Φυσιολογικό', label: cs[0], value: cs[0] },
            ]
        }).flat();

        const extralineData = ev.ExtraCategoriesScores.map((cs, i) => {
            return { name: ev.ExtraCategories[i], value: ev.ExtraCategoriesScores[i] }
        });

        setExtraColumnConfig({
            ...getDefaultColumnConfig(true),
            data: [extracolumnData, extralineData],
        });

    }, [evaluation]);

    return (<>
        {extraRadarConfig &&
            <Col span={24}>
                {extraRadarConfig && <Radar style={{ width: width ?? "100%" }} key={"radar"} {...extraRadarConfig} />}
            </Col>
        }
        {extraColumnConfig &&
            <Col span={24}>
                {extraColumnConfig && <DualAxes key="dual axis" style={{ width: width ?? "100%" }} {...extraColumnConfig} />}
            </Col>
        }
    </>);
}


export const LEPSDSMCharts: React.FC<{ evaluation: EvaluationResultDb, width?: string | number }> = ({ evaluation, width, ...props }) => {
    const [columnConfigDSM, setColumnConfigDSM] = useState<DualAxesConfig | null>(null);
    const [radarConfigDSM, setRadarConfigDSM] = useState<RadarConfig | null>(null);

    useEffect(() => {
        if (!evaluation) {
            return;
        }

        const ev = evaluation.evaluation;

        if (!ev?.DSMResults) {
            return;
        }

        setRadarConfigDSM({
            ...defaultRadarConfig,
            data: ev?.DSMResults.CategoriesDiagnosis?.map((d, i) => ({ name: ev.DSMResults.CategoriesNames[i], value: ev.DSMResults.CategoriesDiagnosis[i] })) ?? [],
        });

        const columnDataDSM = ev.DSMResults.CategoriesScales.map((cs, i) => {
            return [{ name: ev.DSMResults.CategoriesNames[i], category: 'Μέγιστο', label: cs[2], value: cs[2] - cs[1] },
            { name: ev.DSMResults.CategoriesNames[i], category: 'Οριακό', label: cs[1] - 1, value: cs[1] - cs[0] },
            { name: ev.DSMResults.CategoriesNames[i], category: 'Φυσιολογικό', label: cs[0], value: cs[0] },
            ]
        }).flat();

        const lineDataDSM = ev.DSMResults.CategoriesScores.map((cs, i) => {
            return { name: ev.DSMResults.CategoriesNames[i], value: ev.DSMResults.CategoriesScores[i] }
        });

        setColumnConfigDSM({
            ...getDefaultColumnConfig(),
            data: [columnDataDSM, lineDataDSM],
        });

    }, [evaluation]);

    return (<>
        <Col span={24}>
            {radarConfigDSM && <Radar style={{ width: width ?? "100%" }} key={"radar"} {...radarConfigDSM} />}
        </Col>
        <Col span={24}>
            {columnConfigDSM && <DualAxes key="dual axis" style={{ width: width ?? "100%" }} {...columnConfigDSM} />}
        </Col>
    </>);
}
