import { DeleteOutlined, FundViewOutlined, FundTwoTone, DeleteTwoTone, ExclamationCircleOutlined, FolderViewOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Space, Table, TableColumnsType } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import { Answers, EvaluationResultDb, TestType } from "../models/models";

export const AnswersTable: React.FC<{ answers: Array<Answers>, setReady: React.Dispatch<React.SetStateAction<boolean>> }> = ({ answers, setReady, ...props }) => {
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const [columns, setColumns] = useState<TableColumnsType<Answers>>();
    const [testsFilter, setTestsFilter] = useState("");

    const isAdmin = () => {
        return context[0].user?.email == 'admin@clinical-mozart.gr'
    }

    React.useEffect(() => {
        if (!answers) {
            return;
        }

        let uniqueNames = Array.from(new Set(answers.map(a => a.submittedByName)));
        let c: TableColumnsType<Answers> = [
            {
                title: "Αναγνωριστικό", dataIndex: 'id', ellipsis: true,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: "Ονοματεπώνυμο", ellipsis: true, dataIndex: 'name',
                sorter: (a, b) => a.name?.localeCompare(b.name) ?? -1,
            },
            { title: 'Ημ/νια', sorter: (a, b) => moment(a.dateSubmitted).diff(moment(b.dateSubmitted)), ellipsis: true, render: (value, record, index) => { return !value.dateSubmitted ? "" : moment(value.dateSubmitted).format("ddd, DD MMM YYYY") } },
            {
                title: 'Υποβλήθηκε από', dataIndex: 'submittedByName',
                sorter: (a, b) => a.submittedByName?.localeCompare(b.submittedByName), ellipsis: true,
                filters: uniqueNames.map(n => { return { text: n, value: n } }),
                onFilter: (value: string | number | boolean, record: Answers) => record.submittedByName.includes(value as string),
            },
            {
                title: "Κατηγορία", ellipsis: true,
                width: "30%",
                sorter: (a, b) => a.testType - b.testType,
                filters: [
                    { text: TestType[0], value: 0, },
                    { text: TestType[1], value: 1, },
                    { text: TestType[2], value: 2, },
                    { text: TestType[3], value: 3, },
                ],
                onFilter: (value: string | number | boolean, record: Answers) => record.testType == (value as number),
                render: (value, record, index) => {
                    return (<>{TestType[record.testType]}</>)
                }
            },
            {
                title: "", fixed: 'right',
                width: 190, key: "actions", render: (values, record, index) => {
                    return <Space.Compact direction="horizontal">
                        {isAdmin() && <Button type="primary" title="Προβολή αποτελεσμάτων" onClick={() => { evaluationClick(record); }}><FundViewOutlined /></Button>}
                        <Button type="primary" title="Προβολή δεδομένων" onClick={() => { navigate(`/test/${record.testType}`, { state: { answers: record } }); }}><FolderViewOutlined /></Button>
                        <Button type="primary" title="Δημηουργία αντιγράφου" onClick={() => { navigate(`/test/${record.testType}`, { state: { answers: { ...record, id: null } } }); }}><CopyOutlined /></Button>
                        <Button type="primary" danger title="Διαγραφή" disabled={record.id == -1} onClick={() => { deleteEvaluation(record.id) }}><DeleteOutlined /></Button>
                    </Space.Compact>
                }
            },
        ];

        setColumns(c);
    }, [answers]);

    const evaluationClick = async (record: Answers) => {
        if (record.evaluationId != -1) {
            navigate(`/results/${record.evaluationId}`, { replace: true });
            return;
        }

        const response1 = await fetch(`/api/Test/Evaluate?id=${record.id}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });

        if (response1.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την επεξεργασία των αποτελεσματων");
            return;
        }

        try {
            var ev: EvaluationResultDb = await response1.json();
            navigate(`/results/${ev.id}`, { replace: true });
            return;
        } catch (error) {
            message.error("Συνέβει ένα σφάλμα κατα την επεξεργασία των αποτελεσματων");
            return;
        }
    }

    const deleteEvaluationOk = async (id: number) => {
        const response = await fetch(`/api/test/Delete?id=${id}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την διαγραφή");
            return;
        }

        setReady(false);
    }

    const deleteEvaluation = async (id: number) => {
        Modal.confirm({
            title: 'Διαγραφή αξιολόγησης',
            icon: <ExclamationCircleOutlined />,
            content: `Είστε σίγουρος οτι θέλετε να διαγράψετε τη αξιολόγηση αυτή;`,
            okText: 'Ναι',
            cancelText: 'Όχι',
            onOk: () => { deleteEvaluationOk(id) },
            onCancel: () => { },
        });
    }

    return (
        <Table showHeader={true}
            rowKey="id" columns={columns} 
            dataSource={answers.filter(a => TestType[a.testType].toLowerCase().includes(testsFilter.trim()) || a.name.toLowerCase().includes(testsFilter.trim()) || a.id.toString().toLowerCase().includes(testsFilter.trim()))}
            pagination={{
                position: ["topRight"],
                responsive: true, defaultPageSize: 20, total: answers.length,
                showSizeChanger: true, showQuickJumper: false,
                showTotal: (total) =>
                    <Space direction="horizontal" size={5}><Input.Search value={testsFilter} onChange={(e) => { setTestsFilter(e.target.value) }} placeholder="Αναζήτηση" enterButton style={{}} /> <span>{total} Ερωτηματολόγια</span></Space>
            }}
        >
        </Table>);
};

