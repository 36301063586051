import { Card, PageHeader } from "antd"
import { Content } from "antd/lib/layout/layout"
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TestComponent } from "../components/testComponent";
import { Answers, Question } from "../models/models";

export const NewTest: React.FC = props => {
    const [ready, setReady] = useState(false);
    const [questions, setQuestions] = useState<Array<Question>>([]);
    const [answers, setAnswers] = useState<Answers | undefined>();
    let { id } = useParams();
    const { state } = useLocation();

    useEffect(() => {
        if (ready) {
            return;
        }

        (async () => {
            const response = await fetch(`/api/Test/New/${id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            });

            if (response.status === 200) {
                const info = await response.json();
                // for (let i = 0; i < info.length; i++) {
                //     if (!info[i].options) {
                //         continue;
                //     }

                //     info[i].options = JSON.parse(info[i].options);
                //     console.log(info[i].options);
                // }

                setQuestions(info);
            } else {
                setQuestions([]);
            }

            if (state && state.answers) {
                setAnswers(state.answers);
            }
            else {
                setAnswers(undefined);
            }

            setReady(true);
        })();
    }, [id]);

    return (<><PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title={""}
    >
    </PageHeader>
        <Content>
            <TestComponent testType={id} questions={questions} answers={answers}></TestComponent>
        </Content>
    </>);
}